// Auth
.auth-layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
    min-height: 100vh;
    background-image: url(../../images/login-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.auth-form-wrapper{
    padding: 70px 30px 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 70px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    max-width: 700px;
    margin: 0 15px;
}
.auth-logo-row{
    .pcp-smart{
        display: block;
        color: var(--pcp-blue);
        font-size: 71px;
        line-height: 1;
        max-width: max-content;
    }
    .pcp-smart-pcp{}
    .pcp-smart-smart{}
    .pcp-smart-by-line{
        display: block;
        font-size: 16px;
        text-align: right;
        color: #373A3C;
    }
    .pcp-smart-by{
    }
    .pcp-smart-jcinfo{}
}
.auth-form-row{
    max-width: 342px;
    width: 100%;
}
.auth-form{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.auth-contact-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    row-gap: 15px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    max-width: 700px;
    margin: 0 15px;
}
