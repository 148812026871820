.custom-scrollbar {
  @include scrollbar();
}
.color-inherit {
  color: inherit;
}
.maxw-590p {
  max-width: 590px;
}
.bg-opacity-15 {
  --bs-bg-opacity: 0.15;
}
.border-color-input {
  border-color: $input-border-color !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.mw-1100p {
  max-width: 1100px;
}
.mb-15p {
  margin-bottom: 15px;
}
