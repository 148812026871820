.pcp-smart {
}
.pcp-smart-pcp {
}
.pcp-smart-smart {
}
.pcp-smart-by-line {
  display: block;
}
.pcp-smart-by {
}
.pcp-smart-jcinfo {
}

// Header
.header {
  background-color: var(--pcp-blue);
}
.header-content {
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.logo {
  margin-bottom: 0;
  .pcp-smart {
    color: white;
    font-size: 30px;
  }
}
.main-nav {
}
.auth-nav {
}
.pcp-smart {
}
.main-menu {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  column-gap: 10px;
}
.main-menu-li {
}
.main-menu-link {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  padding: 10px 25px;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  transition: background-color 400ms;
  will-change: background-color;
  border-radius: 4px;
  &:hover {
    color: white;
    background-color: #006ac7;
  }

  &.active {
    background-color: #006ac7;
  }
}
.menu-icon {
  font-size: 20px;
}
.auth-menu-icon {
  font-size: 16px;
}
.main-menu-link-small {
  font-size: 11px;
  font-weight: 500;
}
.auth-menu {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  column-gap: 10px;
}
.auth-menu-li {
  + .auth-menu-li {
    border-left: 1px solid white;
    padding-left: 10px;
  }
}
.auth-menu-link {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: white;
  font-size: 14px;
  text-decoration: none;
  padding: 0;
  line-height: 1;
  &:hover {
    color: white;
  }
}
.material-icons-outlined {
}
// $sidebarWidth: 270px;
// $topbarHeight: 63;
// // .default-layout{
// //     padding-top: #{$topbarHeight}px;
// //     height: 100vh;
// // }
// // .default-layout-topbar{
// //     position: fixed;
// //     width: 100%;
// //     top: 0;
// //     left: 0;
// //     width: 100%;
// //     background-color: #dedede;
// //     display: flex;
// //     justify-content: space-between;
// //     height: #{$topbarHeight}px;
// //     z-index: 90;
// // }
// .default-layout-topbar-logo{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     // background-color: black;
//     padding: 10px 20px;
//     // width: $sidebarWidth;
// }
// // .default-layout-topbar-content{
// //     display: flex;
// //     align-items: center;
// //     padding: 0 20px;
// //     height: 100%;
// // }
// .default-layout-content{
//     display: flex;
//     align-items: flex-start;
//     justify-content: flex-start;
// }
// .default-layout-aside{
//     position: fixed;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     width: $sidebarWidth;
//     // height: calc(100% - #{$topbarHeight}px);
//     height: 100%;
//     z-index: 9;
// }
// .default-layout-aside-menu{
//     padding: 0 15px;
//     overflow: scroll;
//     @include scrollbar();
//     flex-grow: 1;
//     // .nav-link{
//     //     color: white;
//     // }
//     // .nav-link:hover .aside-link-text{
//     //     text-decoration: underline;
//     // }
//     // .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
//     //     // background-color: $tef-yellow;
//     //     color: #3B3B3B;
//     //     font-weight: 700;
//     // }
// }
// .default-layout-aside-logout{
//     padding: 15px;
//     border-top: 1px solid rgba($color: white, $alpha: 0.25);
// }
// .aside-li-section-name{
//     display: block;
//     font-size: 14px;
//     text-transform: uppercase;
//     padding: 0 5px;
//     margin: 30px 0 10px;
// }
// .aside-link{
//     display: flex;
//     align-items: center;
//     padding: 6px 8px;
//     font-size: 13px;
//     column-gap: 7px;
// }
// // .aside-link-active{
// //     background-color: ;
// // }
// // .aside-link-text{
// //     margin-left: 15px;
// // }
// .default-layout-main{
//     position: relative;
//     margin-left: $sidebarWidth;
//     flex-grow: 1;
//     min-height: calc(100vh  - #{$topbarHeight}px);
//     overflow: hidden;
// }
// .default-layout-main-content{
//     min-height: calc(100vh  - #{$topbarHeight}px);
// }
// .layout-wrapper{
//     padding: 30px 0 60px;
//     min-height: calc(100vh  - #{$topbarHeight}px);
// }
// // .layout-wrapper-create{
// //     background-color: #eeeeee;
// // }
// .layout-list-wrapper{}
// Loading
.loading-wrapper {
  position: relative;
}
.loading-wrapper-element {
  box-sizing: content-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-color: rgba($color: white, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

// Notification
.toast-container {
  z-index: 2000;
}
.toast-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

// Footer
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.footer-company-stripe {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: #f2f2f2;
  font-style: italic;
  color: #818a91;
}
/*
 | ==============================
 | Home
 | ==============================
*/
.home {
  height: calc(100vh - 199px);
  .pcp-smart {
    position: absolute;
    display: block;
    color: #dedede;
    font-size: 129px;
    line-height: 1;
    max-width: max-content;
    max-height: max-content;
    inset: 0;
    margin: auto;
  }
}
.default-layout-main {
  position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  height: calc(100vh - 73px);
  padding-bottom: 40px;
  .pcp-smart-pcp {
  }
  .pcp-smart-smart {
  }
  .pcp-smart-by-line {
    display: block;
    font-size: 29px;
    text-align: right;
  }
  .pcp-smart-by {
  }
  .pcp-smart-jcinfo {
  }
}
.default-layout-container {
  position: relative;
  background-color: var(--bs-body-bg);
  padding: 32px 20px 90px;
}
.list-serach-input {
  max-width: 400px;
}
